import { Hydrate, QueryClientProvider } from '@tanstack/react-query'
import Analytics from 'components/organisms/analytics'
// import TallosContextProvider from 'features/tallos/contexts/tallos-context'
import AuthContextProvider from 'hooks/auth-context'
import { queryClient } from 'libs/react-query'
import LogRocket from 'logrocket'
import { AppProps } from 'next/app'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { ToastProvider } from 'react-toast-notifications'
import '../styles/globals.css'

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  const LOGROCKET_APP_ID = process.env.NEXT_PUBLIC_LOGROCKET_APP_ID
  LogRocket.init(LOGROCKET_APP_ID)
  return (
    <CookiesProvider>
      <ToastProvider>
        <QueryClientProvider client={queryClient}>
          <AuthContextProvider>
            <Hydrate state={pageProps.dehydratedState}>
              <Component {...pageProps} />
              <Analytics />
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </Hydrate>
          </AuthContextProvider>
        </QueryClientProvider>
      </ToastProvider>
    </CookiesProvider>
  )
}

export default MyApp
