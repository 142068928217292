import CookieKeys from 'constants/cookie-keys'
import { sub } from 'date-fns'
import { getDomain } from 'helpers/get-domain'
import jwtDecode from 'jwt-decode'
import LogRocket from 'logrocket'
import React from 'react'
import { useCookies } from 'react-cookie'
import { UserType } from 'types/user-type'

export type AuthContextProps = {
  children: React.ReactNode
}

export type UseAuthProps = {
  user?: UserType
  signOut: () => void
  setUser: (newUser: UserType) => void
  setUserByAccessToken: (accessToken: string) => void
}

export const AuthContext = React.createContext<UseAuthProps>({
  signOut: () => null,
  setUser: () => null,
  setUserByAccessToken: () => null,
})

const AuthContextProvider = ({ children }: AuthContextProps) => {
  const [user, setUser] = React.useState<UserType | null>()
  const [cookies, setCookie] = useCookies([CookieKeys.ACCESS_TOKEN])

  const setUserByAccessToken = (accessToken: string) => {
    try {
      if (accessToken) {
        const decodedUser = jwtDecode(accessToken) as any
        decodedUser['accessToken'] = accessToken
        setUser(decodedUser)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Erro ao decodificar token de acesso')
    }
  }

  React.useEffect(() => {
    const accessToken = cookies[CookieKeys.ACCESS_TOKEN]
    try {
      if (accessToken) {
        const decodedUser = jwtDecode<UserType>(accessToken)
        setUser(decodedUser)
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  React.useEffect(() => {
    if (user) {
      // loginTallos(user)
      configureLogRocket(user)
    } else {
      // logoutTallos()
    }
  }, [user])

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        setUserByAccessToken,
        signOut: () => {
          // removeCookie(CookieKeys.ACCESS_TOKEN)
          setCookie(CookieKeys.ACCESS_TOKEN, '', {
            path: '/',
            expires: sub(Date.now(), { minutes: 10 }),
            domain: getDomain(location.hostname), // add domain to match with api
          })
          // window.location.href = process.env.NEXT_PUBLIC_ECOMMERCE_LOGIN_URL
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
// const loginTallos = async (user: UserType) => {
//   try {
//     setTimeout(() => {
//       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//       //@ts-ignore
//       if (typeof octadesk !== 'undefined') {
//         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//         //@ts-ignore
//         octadesk?.chat?.login({
//           name: user.name,
//           email: user.email,
//         })
//       }
//     }, 1000)
//   } catch (e) {
//     console.error('Error on login Tallos: ' + e.message)
//   }
// }
// const logoutTallos = () => {
//   try {
//     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//     //@ts-ignore
//     if (typeof octadesk !== 'undefined')
//       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//       //@ts-ignore
//       octadesk.chat.clearSession()
//   } catch (e) {
//     console.error('Error on clearSession Tallos: ' + e.message)
//   }
// }
const configureLogRocket = (user: UserType) => {
  LogRocket.identify(user?.id, {
    name: user?.name,
    email: user?.email,
    // Add your own custom user variables here, ie:
    // subscriptionType: 'pro'
  })
}

export default AuthContextProvider
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAuthContext = () => React.useContext(AuthContext)
