import Script from 'next/script'
import React from 'react'

export interface AnalyticsProps {}
const Analytics: React.FC<AnalyticsProps> = () => {
  const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID
  if (!GA_TRACKING_ID) return null
  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `}
      </Script>
    </>
  )
}

export default Analytics

// https://nextjs.org/docs/messages/next-script-for-ga
// https://willianjusten.com.br/como-configurar-o-google-analytics-no-nextjs-em-2021
