import { useQuery } from '@tanstack/react-query'
import { queryClient } from './query-client'

export const useGlobalState = <T = unknown>(
  key: string | string[],
  initialData?: T
): [data: T, setter: Setter<T>] => {
  const queryKey = typeof key === 'string' ? [key] : key
  const { data } = useQuery({
    queryKey,
    queryFn: () => initialData,
    enabled: false,
    initialData,
  })

  const setter: Setter<T> = (arg: any) => {
    if (typeof arg === 'function') {
      const oldValue = queryClient.getQueryData(queryKey)
      const newValue = arg(oldValue)
      queryClient.setQueriesData(queryKey, newValue)
    }
    queryClient.setQueryData([key], arg)
  }

  return [data, setter]
}

type SetterCallback<T> = (oldValue: T) => T
type Setter<T> = (arg: T | SetterCallback<T>) => void
